<template>
  <div class="card p-4">
    <div class="card-body" style="position: relative; min-height: 70vh">
      <Toast position="top-right" />
      <div v-html="novidade.html"></div>
      <div
        class="d-flex justify-content-end"
        style="position: absolute; top: 32px; right: 32px; cursor: pointer"
      >
        <b-form-checkbox v-model="novidade.visualizado" @change="marcarComoLida"
          >Marcar como não lida</b-form-checkbox
        >
      </div>
    </div>
  </div>
</template>

<script>
import ApiService from "@/core/services/api.service";
import API_LINKS from "../../painel/automacao/api.links.js";
import "moment/locale/pt-br";
import Toast from "primevue/toast";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

export default {
  components: {
    Toast
  },
  data() {
    return {
      novidade: {
        novidade_usuario_id: null,
        titulo: "",
        descricao: "",
        html: "",
        data: "",
        visualizado: false
      }
    };
  },

  methods: {
    async marcarComoLida() {
      const res = await ApiService.patch(
        API_LINKS.novidades + "/" + this.novidade.novidade_usuario_id,
        {
          nov_visualizado: this.novidade.visualizado
        }
      );

      return res;
    },
    async pegarNovidade(id = null) {
      const novidade_id = id ? id : this.$route.params.id;
      const res = await ApiService.get(
        API_LINKS.novidades + "/usuario/" + novidade_id
      );
      this.novidade.titulo = res.data.novidade_usuario.nov_titulo;
      this.novidade.descricao = res.data.novidade_usuario.nov_descricao;
      this.novidade.html = res.data.novidade_usuario.nov_html;
      this.novidade.data = res.data.novidade_usuario.created_at;

      this.novidade.novidade_usuario_id =
        res.data.novidade_usuario.novidade_usuario_id;
      if (!res.data.novidade_usuario.nus_visualizado) {
        this.marcarComoLida();
      }
      return res;
    }
  },
  mounted() {
    const init = async () => {
      await this.pegarNovidade();
      this.$store.dispatch(SET_BREADCRUMB, [
        {
          title: "Notificações"
        },
        {
          title: this.novidade.titulo
        }
      ]);
    };
    init();
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name == "novidade.nova" && to.name == from.name) {
      this.pegarNovidade(to.params.id);
    }
    next();
  }
};
</script>

<style scoped>
.modal-deletar-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.camp-center {
  display: flex;
  width: 50%;
  height: 100%;
}

.botao-opcoes {
  border: none;
  outline: none;
}

.cursor-pointer {
  cursor: pointer;
}

.p-multiselect-trigger-icon.pi.pi-chevron-down::before {
  content: none;
}

table#tabelaNovidades .flip-list-move {
  transition: transform 0.5s;
}
</style>
